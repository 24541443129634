<!--  -->
<template>
    <div class="home aboutIndex">
        <div class="banner">
            <div class="title">国内领先的工会信息服务提供商</div>
        </div>
        <div class="about">
            <div class="wrapper">
                <div class="production">
                    <div class="production_content">
                        <div style="font-size: 20px;font-weight: 600;">关于云窗</div>
                        <div style="font-size: 14px">
                            南京云窗科技专注于智慧工会领域，是国内领先的工会信息化服务提供商。公司致力于智慧工会产品研发、应用及运营，为用户提供智慧工会整体解决方案。</div>
                        <div style="font-size: 14px">自成立以来，云窗科技始终秉持“客户第一、
                            诚信正直、合作创新”的价值观。充分运用互联网、大数据等新技术手段，加快推进智慧工会建设，建立和完善工会数据资源管理体系，建立具备组织网络化、办公智能
                            化、决策数据化、服务普惠化等特点的“网上职工之家”，进步增强工会服务效能， 更好地联系职工、服务职工、引领职工、凝聚职工，走好新时代网上群众路线。</div>
                        <div style="font-size: 14px">
                            公司通过持续的研发投入和产品创新、深刻的行业认知引领行业发展，并深度挖掘场景价值需求，助力工会工作向数字化转型，通过科技驱动工作模式革新，让工会工作变得更智慧!</div>
                    </div>
                    <el-image class="production_img"
                        src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/aboutUs/production.png"
                        fit="cover">
                    </el-image>
                </div>
                <div class="honour">
                    <div class="honour_title">荣誉资质</div>
                    <div class="honour_card">
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">BSIA</div>
                            <div style="font-size: 13px;font-weight: 300;">软件企业证书</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 32px;font-weight: 600;">15+</div>
                            <div style="font-size: 13px;font-weight: 300;">产品著作权</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 32px;font-weight: 600;">10+</div>
                            <div style="font-size: 13px;font-weight: 300;">专利证书</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">
                                <div style="text-align:center">AAA</div>
                                <div>级企业</div>
                            </div>
                            <div style="font-size: 13px;font-weight: 300;">信用等级认证证书</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">ISO9001</div>
                            <div style="font-size: 13px;font-weight: 300;">质量管理体系</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">ISO27001</div>
                            <div style="font-size: 13px;font-weight: 300;">信息安全管理体系</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">ISO45001</div>
                            <div style="font-size: 13px;font-weight: 300;">职业健康安全管理</div>
                        </div>
                        <div class="card_wrapper">
                            <div style="font-size: 24px;font-weight: 600;">CSEE</div>
                            <div style="font-size: 13px;font-weight: 300;">软件企业证书</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="value_bg">
            <div class="value_wrapper">
                <div class="value_content" @mouseover="num = 1" @mouseout="num = 0">
                    <div style="font-size:22px;color:#fff;font-weight: 500;margin-bottom: 20px;">
                        云窗坚持什么</div>
                    <transition name="fade">
                        <div style="font-size:13px;color:#fff;font-weight: 300;line-height: 1.5;" v-show="num == 1">
                            我们专注智慧工会,以人为中心,以服务创价值。改善工会服务方式及流程，通过“互联网+工会”的深度创新,不断完善产品和服务,真正实现理解工会、帮助工会、提升工会。</div>
                    </transition>
                </div>
                <div class="value_content" @mouseover="num = 2" @mouseout="num = 0">
                    <div style="font-size:22px;color:#fff;font-weight: 500;margin-bottom: 20px;">云窗倡导什么</div>
                    <transition name="fade">
                        <div style="font-size:13px;color:#fff;font-weight: 300;line-height: 1.5;" v-show="num == 2">
                            我们专注智慧工会,以人为中心,以服务创价值。改善工会服务方式及流程，通过“互联网+工会”的深度创新,不断完善产品和服务,真正实现理解工会、帮助工会、提升工会。</div>
                    </transition>
                </div>
                <div class="value_content" @mouseover="num = 3" @mouseout="num = 0">
                    <div style="font-size:22px;color:#fff;font-weight: 500;margin-bottom: 20px;">云窗如何让工会更智慧</div>
                    <transition name="fade">
                        <div style="font-size:13px;color:#fff;font-weight: 300;line-height: 1.5;" v-show="num == 3">
                            我们专注智慧工会,以人为中心,以服务创价值。改善工会服务方式及流程，通过“互联网+工会”的深度创新,不断完善产品和服务,真正实现理解工会、帮助工会、提升工会。</div>
                    </transition>
                </div>
                <div class="value_content" @mouseover="num = 4" @mouseout="num = 0">
                    <div style="font-size:22px;color:#fff;font-weight: 500;margin-bottom: 20px;">
                        云窗为何而努力</div>
                    <transition name="fade">
                        <div style="font-size:13px;color:#fff;font-weight: 300;line-height: 1.5;" v-show="num == 4">
                            我们专注智慧工会,以人为中心,以服务创价值。改善工会服务方式及流程，通过“互联网+工会”的深度创新,不断完善产品和服务,真正实现理解工会、帮助工会、提升工会。</div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="culture">
            <div class="culture_body">
                <div class="culture_title">企业文化</div>
                <div class="culture_card">
                    <div class="culture_card_wrapper">
                        <div class="culture_icon">
                            <el-image style="width: 40px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/aboutUs/culture_icon1.png"
                                fit="cotain">
                            </el-image>
                        </div>
                        <div class="culture_content">
                            <div style="font-size:20px;font-weight:500;margin-bottom:10px">公司文化</div>
                            <div style="font-size: 15px;font-weight: 300;">简单、自由、平等</div>
                        </div>
                    </div>
                    <div class="culture_card_wrapper">
                        <div class="culture_icon">
                            <el-image style="width: 40px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/aboutUs/culture_icon2.png"
                                fit="cotain">
                            </el-image>
                        </div>
                        <div class="culture_content">
                            <div style="font-size:20px;font-weight:500;margin-bottom:10px">使命</div>
                            <div style="font-size: 15px;font-weight: 300;">持续为用户提供高价值的解决方案</div>
                        </div>
                    </div>
                    <div class="culture_card_wrapper">
                        <div class="culture_icon">
                            <el-image style="width: 40px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/aboutUs/culture_icon3.png"
                                fit="cotain">
                            </el-image>
                        </div>
                        <div class="culture_content">
                            <div style="font-size:20px;font-weight:500;margin-bottom:10px">愿景</div>
                            <div style="font-size: 15px;font-weight: 300;">让用户服务舒心且智慧</div>
                        </div>
                    </div>
                    <div class="culture_card_wrapper">
                        <div class="culture_icon">
                            <el-image style="width: 40px"
                                src="https://yunch-1302520809.cos.ap-nanjing.myqcloud.com/yc-website/images/aboutUs/culture_icon4.png"
                                fit="cotain">
                            </el-image>
                        </div>
                        <div class="culture_content">
                            <div style="font-size:20px;font-weight:500;margin-bottom:10px">价值观</div>
                            <div style="font-size: 15px;font-weight: 300;">诚信正直、合作创新、客户第一</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import foot from '@/components/footer/footer.vue'
export default {
    metaInfo: {
      title: '云窗科技', // set a title
      meta: [{                 // set meta
        name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
        content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: 'http://yunchuangtech.com/'
      }]
   },
    data() {
        return {
            num: 0
        }
    },
    components: {
        foot
    },
    methods: {

    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
</style>